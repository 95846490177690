.navbar {
  height: 35px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.menu-bars {
  margin: 10px;
  margin-left: 20px;
  margin-top: 20px;
  padding: 5px;
  color: #fff;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.menu-bars-icon{
  width: 100%;
  height: 100%;
}

.menu-bars:hover {
  background-color: red;
}

.nav-menu {
  background-color: #10160F;
  color: white;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 30%;
}

@media only screen and (max-width: 768px){
  .nav-menu {
    width: 100%;
  }
}

.nav-menu.active {
  left: 0;
  transition: 450ms;
}

.navbar-utils {
  position: relative;
  height: 100%;
}

.disclaimer-icon {
  color: yellow;
}

.projectInfo{
  position: absolute;
  bottom: 0;
  padding: 2px;
  margin: auto;
  margin-bottom: 10px;
  text-align: center;
}

.p-disclaimer {
  margin: 10px;
  font-size: 90%;
}

.p-about {
  margin: 10px;
  font-size: 70%;
}

.inner-navbar {
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.inner-navbar-menu-bars {
  margin: 5px;
  padding: 10px;
  color: #fff;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.inner-navbar-menu-bars-icon{
  width: 100%;
  height: 100%;
}

.inner-navbar-menu-bars:hover {
  background-color: #212E1F;
}

.nav-sub-container {
  margin: 8px;
}

.access-loc-container {
  margin: 10px;
}

.access-loc-inputs {
  display: inline-flex;
}

.access-loc-inputs input[type=checkbox] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background-clip: content-box;
    border: 1.5px solid #bbbbbb;
    border-radius: 6px;
    background-color: #D43300;
    cursor: pointer;
/*
    when checked: 00D400;
    when not checked: e7e6e7;*/

    &:checked{
        background-color: #00D400;
    }

    &:focus{
        outline: none !important;
    }
}

.access-loc-label {
  margin: auto;
  margin-left: 2px;
}

.access-loc-error {
  color: red;
  text-align: center;
  font-size: 14px;
}

.access-loc-waiting-container {
  display:inline-flex;
  margin: 18px;
}

.access-loc-waiting-container label {
  margin: auto;
  margin-left: 12px;
}


.access-loc-waiting-spinner {
  color: blue;
  margin: auto;
}


