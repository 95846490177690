.logout-btn {
  padding: 8px 16px;
  border: none;
  background-color: #0A5200;
  color: #fff;
  border-radius: 16px;
  font-size: 12px;
  cursor: pointer;
  margin: 10px;
}

.logout-btn:hover {
   background-color: #052700;
}