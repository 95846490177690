.register-form {
  padding: 4px;
}

.register-form_title{
  font-size: 20px;
  font-weight: bold;
  margin: 8px;
}

.register-input-form input {
  background-color: #282A28;
  color: #999999;
  padding: 8px;
  border: 1px solid #282A28;
  border-radius: 10px 10px 10px 10px;
  font-size: 12px;
  margin: 10px;
  width: 60%;
}

.register-input-form button {
  padding: 8px 16px;
  border: none;
  background-color: #0A5200;
  color: #fff;
  border-radius: 16px 16px 16px 16px;
  font-size: 16px;
  cursor: pointer;
  margin: 4px;
}
.register-input-form button:hover {
  background-color: #052700;
}

.go-to-login-link {
  color: #0067B7;
  cursor: pointer;
}

.go-to-login-link:hover {
  color: #00355F;
}
.error_msg_txt {
  color: red;
  margin: 4px;
}

.success_msg_txt {
  color: green;
  margin: 4px;
}


