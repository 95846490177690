/* Chatbot.css */

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #212921 #161B16;
}
/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}
*::-webkit-scrollbar-track {
  background: #161B16;
}
*::-webkit-scrollbar-thumb {
  background-color: #212921;
  border-radius: 20px;
  border: 3px solid #161B16;
}

.chatbot-container {
  width: 55%;
  margin: 0 auto;
  height: 100%;
  border-radius: 8px;
  padding: 10px;
  float: middle;
}
@media only screen and (max-width: 768px){
  .chatbot-container {
    width: 90%;
  }
}
.chatbot-messages {
  height: 60%;
  overflow-y: scroll;
  padding: 8px;
}

.message {
  margin: 8px 0;
  padding: 8px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.5;
}
.user-message {
  display: flex;
  background-color: #252C25;
  text-align: left;
  color: #fff;
  margin-left: 0; 
  margin-right: auto;
  overflow-wrap: break-word;
  flex-wrap: wrap;
}
.user-message img{
  margin: 10px;
  margin-bottom: auto;
  margin-top: auto;
}
.ai-message {
  display: flex;
  text-align: left;
  color: #fff;
  margin-left: 0; 
  margin-right: auto;
  overflow-wrap: break-word;
  flex-wrap: wrap;
}
.ai-message img{
  margin: 10px;
  margin-bottom: auto;
  margin-top: auto;
}
.input-global {
  display: flex; 
  flex-direction: column;
}
.chatbot-input-form {
  display: flex;
  margin-top: 12px;
  order: 2;
}
.input-container {
  width: 100%;
  display:flex; 
  flex-direction:row;
}
.chatbot-input-form input {
  background-color: #282A28;
  color: #999999;
  float: left;
  margin: 4px;
  padding: 8px;
  width: 90%;
  border: 1px solid #282A28;
  border-radius: 10px 10px 10px 10px;
  font-size: 90%;
}

@media only screen and (max-width: 768px){
  .chatbot-input-form input {
    width: 90%;
  }
}
.inputBtns {
  display:flex; 
  flex-direction:row;
}
.sendBtnContainer{
  order: 2;
}
.sendBtn {
  padding: 8px 16px;
  border: none;
  background-color: #0A5200;
  color: #fff;
  float: right;
  margin: 5px;
  border-radius: 4px 16px 16px 4px;
  font-size: 12px;
  cursor: pointer;
  width: 60px;

}
.sendBtn:hover {
  background-color: #052700;
}
.audioBtnContainer{
  order: 1;
}
.audioBtn {
  padding: 8px;
  border: none;
  background-color: #0A5200;
  color: #fff;
  float: right;
  margin: 5px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
}

.audioBtn:hover {
  background-color: #052700;
}

.audioBtnHold {
  padding: 8px;
  border: none;
  background-color: red;
  color: #fff;
  float: right;
  margin: 5px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
}

.audioBtnHold:hover {
  background-color: red;
}

.prev-button-inputs{
  margin: 8px;
  order: 1;
}

.prev-button-inputs button {
  padding: 8px 16px;
  border: 1px solid;
  background-color: #161E15;
  color: #fff;
  border-radius: 6px;
  font-size: 80%;
  cursor: pointer;
  margin: 4px;
  width: 30%;
/*  margin-bottom: auto;*/
}

@media only screen and (max-width: 768px){
  .prev-button-inputs button {
    font-size: 70%;
  }
}

.prev-button-inputs button:hover {
  background-color: #151C14;
}

.clear-btn-container {
  margin: auto;
}

.chatbot-clear-btn {
  padding: 4px 8px;
  border: none;
  background-color: #0A5200;
  color: #fff;
  border-radius: 8px;
  font-size: 12px;
  cursor: pointer;
  margin: 10px;
}
.chatbot-clear-btn:hover {
  background-color: #052700;
}

